import axios from 'axios'
import {notify} from "../../components/Notify";
import {nodeFromString} from "../../tools/NodeFromString";

export default function doctorEducations() {
    let container = document.querySelector('.doctor-educations-container')

    if (container) {
        let list = container.querySelector('.doctor-educations'),
            count = container.querySelectorAll('.doctor-education').length

        function initItem(item) {
            item.querySelector('.doctor-education-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
            })
        }

        function initAdd() {
            container.querySelector('.doctor-education-add')?.addEventListener('click', e => {
                e.preventDefault()
                axios.get(`/admin/doctors/get-education?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }

        initAdd()
        container.querySelectorAll('.doctor-education').forEach(initItem)
    }
}

