import axios from 'axios'
import {notify} from "../components/Notify";
import {nodeFromString} from "../tools/NodeFromString";

document.addEventListener('DOMContentLoaded', () => {
    let selectCategory = $('#categoryId')
    let selectSubcategory = $('#subcategoryId')

    if (selectCategory && selectSubcategory) {
        selectCategory.on('select2:select', function (e) {
            if (e.target.value) {
                selectSubcategory.val('').trigger('change')
                selectSubcategory.attr('disabled', 'disabled')
            } else {
                selectSubcategory.removeAttr('disabled')
            }

        })
        selectSubcategory.on('select2:select', function (e) {
            if (e.target.value) {
                selectCategory.val('').trigger('change')
                selectCategory.attr('disabled', 'disabled')
            } else {
                selectCategory.removeAttr('disabled')
            }
        })
    }

    let container = document.getElementById('services-container')
    if (container) {
        let list = document.getElementById('services-list'),
            count = container.querySelectorAll('.service-item').length

        function initItem(item) {
            item.querySelector('.service-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
            })
        }

        $("#service-selector")?.on('change', e => {
            e.preventDefault()
            addService()
        })

        function addService() {
            let serviceSelector = document.getElementById('service-selector')
            if (serviceSelector.value) {
                if (container.querySelectorAll(`[data-service-id='${serviceSelector.value}']`).length > 0) {
                    notify('Ошибка', 'danger', 'Услуга уже в списке')
                    return;
                }
                axios.get(`/admin/services/external-service-add/${serviceSelector.value}?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            } else {
                notify('Ошибка', 'danger', 'Для добавления выберите услугу')
            }
        }

        container.querySelectorAll('.service-item').forEach(initItem)
    }

    let containerReviews = document.getElementById('reviews-container')
    if (containerReviews) {
        let list = document.getElementById('reviews-list'),
            count = containerReviews.querySelectorAll('.reviews-item').length

        function initItemReview(item) {
            item.querySelector('.reviews-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
            })
        }

        $("#reviews-selector")?.on('change', e => {
            e.preventDefault()
            addReview()
        })

        function addReview() {
            let reviewSelector = document.getElementById('reviews-selector')
            if (reviewSelector.value) {
                if (containerReviews.querySelectorAll(`[data-reviews-id='${reviewSelector.value}']`).length > 0) {
                    notify('Ошибка', 'danger', 'Услуга уже в списке')
                    return;
                }
                axios.get(`/admin/services/review-add/${reviewSelector.value}?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItemReview(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            } else {
                notify('Ошибка', 'danger', 'Для добавления выберите услугу')
            }
        }

        containerReviews.querySelectorAll('.reviews-item').forEach(initItemReview)
    }
})